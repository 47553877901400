import { Injectable } from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';
import {map} from 'rxjs/operators';

const API_USERS_LOGIN = `${environment.apiUrl}/api/login`;
const API_USERS_REGISTER = `${environment.apiUrl}/api/register`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) { }

  // public methods
  login(email: string, password: string): Observable<any> {
    return this.http.post<AuthModel>(API_USERS_LOGIN,   {
      // grant_type: 'password',
      // scope: '*',
      username: email,
      password
    }).pipe(map(resAuth => {
      const auth = new AuthModel();
      auth.accessToken = resAuth['access_token'];
      auth.refreshToken = resAuth['refresh_token'];
      auth.expiresIn = new Date(Date.now() + 100 * 24 * 60 * 60 * 1000);
      return auth;
    }));
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(API_USERS_REGISTER, user);
  }

  // async getUser() {
  //   const me = await this.http.get<any>(`${environment.apiUrl}/api/me`).toPromise();
  //   const permissions = await this.http.get<any>(`${environment.apiUrl}/api/me/permissions`).toPromise();
  //   const user: UserModel = {
  //     ...me.data,
  //     permissions: permissions.data
  //   };
  //   return user;
  // }

  getUser(): Observable<any> {
    return forkJoin([
      this.http.get<any>(`${environment.apiUrl}/api/me`),
      this.http.get<any>(`${environment.apiUrl}/api/me/permissions`),
    ]).pipe(map((res) => {
      const user: UserModel = {
        ...res[0].data,
        permissions: res[1].data,
      };
      return user;
    }));
  }

  updateUser(data) {
    const url = `${environment.apiUrl}/api/me`;
    return this.http.patch<any>(url, data).pipe(
        map((response: any) => {
          return response.data.data;
        })
    );
  }


  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_LOGIN}/forgot-password`, {
      email,
    });
  }

    getUserByToken(token): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<UserModel>(`${API_USERS_LOGIN}`, {
      headers: httpHeaders,
    });
  }
}
