import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../app/modules/auth';
import {environment} from './environment';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Injectable()
export class Interceptor implements HttpInterceptor {
    /**
     * Constructor
     *
     */
    constructor(
        private authService: AuthService,
        private router: Router,
        private alocation: Location
    ) {
    }

    /**
     * Intercept
     *
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // const domain = environment.domain;
        const domain = location.host;

        const headerConfig = {
            'x-api-domain': domain,
        };

        if (localStorage.getItem('access_token')) {
            headerConfig['Authorization'] = 'Bearer ' + JSON.parse(localStorage.getItem('access_token'));
        }

        req = req.clone({
            setHeaders: headerConfig
        });

        return next.handle(req).pipe(
            catchError((error) => {

                // Catch "401 Unauthorized" responses
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    // Sign out
                    this.authService.logout();

                    // Reload the app
                    location.reload();
                }
                //
                // // Catch "404 Not found" responses
                if (error instanceof HttpErrorResponse && error.status === 404) {
                    this.router.navigate(['error']);
                }

                return throwError(error);
            })
        );
    }
}
