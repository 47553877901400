// tslint:disable:variable-name
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {PaginatorState} from '../models/paginator.model';
import {ITableState, TableResponseModel} from '../models/table.model';
import {BaseModel} from '../models/base.model';
import {SortState} from '../models/sort.model';
import {GroupingState} from '../models/grouping.model';
import {environment} from '../../../../../environments/environment';
import {apiFilter} from '../../../../modules/apifilter/apifilter';

const DEFAULT_STATE: ITableState = {
    filter: {},
    paginator: new PaginatorState(),
    sorting: new SortState(),
    searchTerm: '',
    grouping: new GroupingState(),
    entityId: undefined
};

export abstract class TableService<T> {
    // Private fields
    protected _items$ = new BehaviorSubject<T[]>([]);
    protected _isLoading$ = new BehaviorSubject<boolean>(false);
    protected _isFirstLoading$ = new BehaviorSubject<boolean>(true);
    protected _tableState$ = new BehaviorSubject<ITableState>(DEFAULT_STATE);
    protected _errorMessage = new BehaviorSubject<string>('');
    protected _subscriptions: Subscription[] = [];
    public errorMessage = '';
    public errorFields = {};

    // Getters
    get items$() {
        return this._items$.asObservable();
    }

    get isLoading$() {
        return this._isLoading$.asObservable();
    }

    get isFirstLoading$() {
        return this._isFirstLoading$.asObservable();
    }

    get errorMessage$() {
        return this._errorMessage.asObservable();
    }

    get subscriptions() {
        return this._subscriptions;
    }

    // State getters
    get paginator() {
        return this._tableState$.value.paginator;
    }

    get filter() {
        return this._tableState$.value.filter;
    }

    get sorting() {
        return this._tableState$.value.sorting;
    }

    get searchTerm() {
        return this._tableState$.value.searchTerm;
    }

    get grouping() {
        return this._tableState$.value.grouping;
    }

    protected http: HttpClient;
    // API URL has to be overrided
    API_URL = `${environment.apiUrl}/endpoint`;

    constructor(http: HttpClient) {
        this.http = http;
    }

    // CREATE
    // server should return the object with ID
    create(item: BaseModel): Observable<BaseModel> {
        this._isLoading$.next(true);
        this._errorMessage.next('');
        return this.http.post<BaseModel>(this.API_URL, item).pipe(
            catchError(err => {
                // this._errorMessage.next(err.error?.message);
                if (err.error) {
                    if (err.error.message) { this.errorMessage = err.error?.message; }
                    else if (err.error?.errors) { this.errorMessage = String(Object.values(err.error?.errors)[0][0]); }
                    if (err.error?.errors) { this.errorFields = err.error?.errors; }
                    else if (err.error?.error) { this.errorFields = err.error.error;}
                }
                console.error('CREATE ITEM', err);
                return of(err.error);
            }),
            finalize(() => { this._isLoading$.next(false); setTimeout(() => { this.errorMessage = ''; this.errorFields = {}; }, 10000); })
        );
    }

    // UPDATE
    update(item: BaseModel): Observable<any> {
        const url = `${this.API_URL}/${item.id}`;
        this._isLoading$.next(true);
        this._errorMessage.next('');
        return this.http.patch<BaseModel>(url, item).pipe(
            catchError(err => {
                // this._errorMessage.next(err.error?.message);
                if (err.error.message) { this.errorMessage = err.error?.message; }
                else if (err.error?.errors) { this.errorMessage = String(Object.values(err.error?.errors)[0][0]); }
                if (err.error?.errors) { this.errorFields = err.error?.errors; }
                else if (err.error.error) { this.errorFields = err.error.error[0]; }
                console.error('CREATE ITEM', err);
                return of(err.error);
            }),
            finalize(() => { this._isLoading$.next(false); setTimeout(() => { this.errorMessage = ''; this.errorFields = {}; }, 10000); })
        );
    }

    // READ (Returning filtered list of entities)
    // find(tableState: ITableState): Observable<TableResponseModel<T>> {
    //   const url = this.API_URL + '/find';
    //   this._errorMessage.next('');
    //   return this.http.post<TableResponseModel<T>>(url, tableState).pipe(
    //     catchError(err => {
    //       this._errorMessage.next(err);
    //       console.error('FIND ITEMS', err);
    //       return of({ items: [], total: 0 });
    //     })
    //   );
    // }

    reloadPaginator() {
        this._tableState$.value.paginator = new PaginatorState();
    }

    // READ
    find(tableState: ITableState): any {
        const params = {
            ...tableState.filter,
            page: String(tableState.paginator.page),
            per_page: String(tableState.paginator.pageSize),
        };
        // if (!Object.keys(tableState.filter).includes(tableState.sorting.column)) {
        //     params[`${tableState.sorting.column}[orderBy]`] = tableState.sorting.direction;
        // }
        if (tableState.sorting.column) {
            params['order'] = tableState.sorting.direction;
            params['orderBy'] = tableState.sorting.column;
        }
        return this.http.get<any[]>(this.API_URL, {params}).pipe(
            map((response: any) => {
                    const filteredResult = apiFilter(response.data, tableState);
                    const result: TableResponseModel<any> = {
                        items: filteredResult.items,
                        total: filteredResult.total
                    };
                    return result;
                },
                catchError(err => {
                    this._errorMessage.next(err);
                    this.errorMessage = String(Object.values(err.error?.errors)[0][0]) || err.error?.message;
                    return of({items: [], total: 0});
                }))
        );
    }

    getItemById(id: number): Observable<BaseModel> {
        this._isLoading$.next(true);
        this._errorMessage.next('');
        const url = `${this.API_URL}/${id}`;
        return this.http.get<BaseModel>(url).pipe(
            catchError(err => {
                this._errorMessage.next(err);
                console.error('GET ITEM BY IT', id, err);
                return of({id: undefined});
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    // UPDATE Status
    updateStatusForItems(ids: number[], status: number): Observable<any> {
        this._isLoading$.next(true);
        this._errorMessage.next('');
        const body = {ids, status};
        const url = this.API_URL + '/updateStatus';
        return this.http.put(url, body).pipe(
            catchError(err => {
                this._errorMessage.next(err);
                console.error('UPDATE STATUS FOR SELECTED ITEMS', ids, status, err);
                return of([]);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    // DELETE
    delete(id: any): Observable<any> {
        this._isLoading$.next(true);
        this._errorMessage.next('');
        const url = `${this.API_URL}/${id}`;
        return this.http.delete(url).pipe(
            catchError(err => {
                this._errorMessage.next(err);
                console.error('DELETE ITEM', id, err);
                return of({});
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    // delete list of items
    deleteItems(ids: number[] = []): Observable<any> {
        this._isLoading$.next(true);
        this._errorMessage.next('');
        const url = this.API_URL + '/delete_mass';
        const body = {ids};
        return this.http.request('delete', url, {body}).pipe(
            catchError(err => {
                this._errorMessage.next(err);
                console.error('DELETE SELECTED ITEMS', ids, err);
                return of([]);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    public fetch() {
        this._isLoading$.next(true);
        this._errorMessage.next('');
        const request = this.find(this._tableState$.value)
            .pipe(
                tap((res: TableResponseModel<T>) => {
                    this._items$.next(res.items);
                    this.patchStateWithoutFetch({
                        paginator: this._tableState$.value.paginator.recalculatePaginator(
                            res.total
                        ),
                    });
                }),
                catchError((err) => {
                    this._errorMessage.next(err);
                    this._items$.next([]);
                    return of({
                        items: [],
                        total: 0
                    });
                }),
                finalize(() => {
                    this._isLoading$.next(false);
                    const itemIds = this._items$.value.map((el: T) => {
                        const item = (el as unknown) as BaseModel;
                        return item.id || item['package_id'];
                    });
                    this.patchStateWithoutFetch({
                        grouping: this._tableState$.value.grouping.clearRows(itemIds),
                    });
                })
            )
            .subscribe();
        this._subscriptions.push(request);
    }

    // Base Methods
    public patchState(patch: Partial<ITableState>) {
        this.patchStateWithoutFetch(patch);
        this.fetch();
    }

    public patchStateWithoutFetch(patch: Partial<ITableState>) {
        const newState = Object.assign(this._tableState$.value, patch);
        this._tableState$.next(newState);
    }
}
