export let environment = {
    domain: 'adposting.dev.zenzenzen.net',
    apiUrl: 'https://poster.payments.dev.zenzenzen.net',
    config: {}
};

export function toggleBackend() {
    environment = {
        domain: 'adposting2.dev.zenzenzen.net',
        apiUrl: 'https://poster.payments.dev.zenzenzen.net',
        config: {}
    };
}

export function toggleControl() {
    environment = {
        domain: 'adposting.dev.zenzenzen.net',
        apiUrl: 'https://poster.payments.dev.zenzenzen.net',
        config: {}
    };
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
