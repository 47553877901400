<!-- splash screen -->
<div #splashScreen id="splash-screen">
  <img [src]="getLogo()" alt="Logo" style="max-width: 128px; max-height: 128px;" />
  <svg class="splash-spinner" viewBox="0 0 50 50">
    <circle
      class="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      stroke-width="5"
    ></circle>
  </svg>
</div>
