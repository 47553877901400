import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Observable, BehaviorSubject, of, Subscription} from 'rxjs';
import {map, catchError, switchMap, finalize} from 'rxjs/operators';
import {UserModel} from '../_models/user.model';
import {AuthModel} from '../_models/auth.model';
import {AuthHTTPService} from './auth-http/auth-http.service';
import {Router} from '@angular/router';
import {NgxPermissionsService} from 'ngx-permissions';

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    // private fields
    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    // public fields
    currentUser$: Observable<any>;
    isLoading$: Observable<boolean>;
    currentUserSubject: BehaviorSubject<any>;
    isLoadingSubject: BehaviorSubject<boolean>;


    get currentUserValue(): UserModel {
        return this.currentUserSubject.value;
    }

    set currentUserValue(user: UserModel) {
        this.currentUserSubject.next(user);
    }

    constructor(
        private authHttpService: AuthHTTPService,
        private router: Router,
        private permissionsService: NgxPermissionsService,
    ) {
        this.isLoadingSubject = new BehaviorSubject<boolean>(false);
        this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
        this.currentUser$ = this.currentUserSubject.asObservable();
        this.isLoading$ = this.isLoadingSubject.asObservable();
        // const subscr = this.getUserByToken().subscribe();
        // this.unsubscribe.push(subscr);
    }

    // public methods
    login(email: string, password: string): Observable<UserModel> {
        this.isLoadingSubject.next(true);
        return this.authHttpService.login(email, password).pipe(
            map((auth: AuthModel) => {
                const result = this.setAuthFromLocalStorage(auth);
                return result;
            }),
            switchMap(() => this.getUserByToken()),
            catchError((err) => {
                console.error('err', err);
                return of(undefined);
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    logout() {
        localStorage.removeItem('access_token');
        this.router.navigate(['/auth/login'], {
            queryParams: {},
        });
    }

    getUserByToken(): Observable<UserModel> {
        const auth = this.getAuthFromLocalStorage();
        if (!auth) {
            return of(undefined);
        }

        this.isLoadingSubject.next(true);
        return this.authHttpService.getUser().pipe(
            map((user: any) => {
                if (user) {
                    this.currentUserSubject = new BehaviorSubject<UserModel>(user);
                    this.permissionsService.loadPermissions(user.permissions);
                } else {
                    this.logout();
                }
                return user;
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    // need create new user then login
    registration(user: any): Observable<any> {
        this.isLoadingSubject.next(true);
        return this.authHttpService.createUser(user).pipe(
            map(() => {
                this.isLoadingSubject.next(false);
            }),
            switchMap(() => this.login(user.email, user.password)),
            catchError((err) => {
                console.error('err', err);
                return of(undefined);
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    forgotPassword(email: string): Observable<boolean> {
        this.isLoadingSubject.next(true);
        return this.authHttpService
            .forgotPassword(email)
            .pipe(finalize(() => this.isLoadingSubject.next(false)));
    }

    // private methods
    private setAuthFromLocalStorage(auth: AuthModel): boolean {
        // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
        if (auth && auth.accessToken) {
            localStorage.setItem('access_token', JSON.stringify(auth.accessToken));
            return true;
        } else {
            return false;
        }
    }

    private getAuthFromLocalStorage(): AuthModel {
        try {
            const authData = JSON.parse(
                localStorage.getItem('access_token')
            );
            return authData;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    update(data): Observable<any> {
        this.isLoadingSubject.next(true);
        return this.authHttpService.updateUser(data);
    }

    finishUpdate() {
        this.isLoadingSubject.next(false);
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
