/** Angular */
import {ChangeDetectorRef, Injectable} from '@angular/core';

import {FormGroup} from '@angular/forms';

import numeral from 'numeral';

@Injectable()
export class FormUtilsService {

    constructor() {
    }

    getErrorMessage(form: FormGroup, controlName: string, label: string = '', minLength: number = 3, maxlength: number = 320, minValue: number = 1): any {
        const control = form.controls[controlName];
        if (control.hasError('minlength')) {
            return 'Minimum field length: ' + minLength;
        }
        if (control.hasError('pattern') && controlName === 'link') {
            return 'You entered an incorrect link';
        }
        if (control.hasError('maxlength')) {
            return 'Maximum field length: ' + maxlength;
        }
        if (control.hasError('matDatetimePickerMin')) {
            return 'Stop at must be after Start at';
        }
        if (control.hasError('min')) {
            return 'The value must be greater than ' + minValue;
        }
        if (control.hasError('email')) {
            return 'Not a valid email';
        }
        if (control.hasError('validatePhoneNumber')) {
            return 'Not a valid phone number';
        }
        if (control.hasError('mustMatch')) {
            return 'New password and confirm new password do not match';
        }
        if (control.hasError('serverError')) {
            return control.getError('serverError');
        }
        return control.hasError('required') ? label ?
            `${label} ${label.slice(-1) === 's' ? 'are' : 'is'} required`
            : `You must enter a value` : Object.keys(control.errors)[0];
    }

    prepareServerError(response: any, form: FormGroup, cdr: ChangeDetectorRef): any {
        if (!response || !response.error) {
            return console.log('!response.error');
        }
        let errors = response.error.errors;
        if (!errors && response.error.error && typeof response.error.error !== 'string') {
            errors = response.error.error;
        }
        if (errors) {
            Object.keys(errors).map(field => {
                let error = '';
                if (errors[field] && errors[field].length) {
                    errors[field].map(text => {
                        error += text;
                    });
                }
                form.controls[field].setErrors({serverError: error});
            });
            // Mark for check
            cdr.markForCheck();
            const message = response.error.message ? response.error.message : 'The given data was invalid.';
            return this.getMessage(message);
        } else {
            // Show the error message
            let message = 'The given data was invalid.';
            if (response.error.error && typeof response.error.error === 'string') {
                message = response.error.error;
            } else if (response.error && response.error === 'string') {
                message = response.error;
            }
            return this.getMessage(message);
        }
    }

    getMessage(message: string, type: string = 'error'): any {
        return {
            appearance: 'outline',
            content: message,
            shake: true,
            showIcon: false,
            type
        };
    }

    selectFilter(selectArray: any, value: string): string[] {
        const filterValue = this.normalizeValue(value);
        return selectArray.filter(item => {
            const itemValue = item.name ? item.name : item.label ? item.label : item;
            return this.normalizeValue(itemValue).includes(filterValue);
        });
    }

    normalizeValue(value: string): string {
        return value.toLowerCase().replace(/\s/g, '');
    }


    cleanIncludeExclude(value: string, isValidEmail: boolean = false): string {
        const emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        value = this.delBlankLines(value);
        value = value.replace(/[,]{2,}/g, ',').replace(/%/g, '')
            .replace(/\r\n|\n|\r/g, ',').replace(/(^[ \t]*\n)/gm, '');
        let tempArr = value.split(',');
        if (tempArr && tempArr.length) {
            tempArr = tempArr.map(val => {
                return val.trim().replace(/\s{2,}/g, ' ').replace(/\.$/, '');
            });
            if (isValidEmail) {
                tempArr = tempArr.filter(val => {
                    return emailReg.test(val);
                });
            }
            // удаление пустых елементов
            tempArr = tempArr.filter(val => val);
            // удаление одинаковых елементов
            tempArr = [...new Set(tempArr)];
            return tempArr.join(',');
        }
        return '';
    }

    delBlankLines(text): string {
        const stringArray = text.split('\n');
        const temp = [''];
        let x = 0;
        stringArray.map(val => {
            if (!!val) {
                temp[x] = val;
                x++;
            }
        });

        return temp.join('\n');
    }

    isNumeric(n): boolean {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    validationEmail(val: string): boolean {
        const emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return emailReg.test(val);
    }

    formatMoney(val: string | number): string {
        return numeral(val).format('0,0.00');
    }

    capitalize(s): string {
        return s[0].toUpperCase() + s.slice(1);
    }
}
