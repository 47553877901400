import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit, Inject, Injectable,
} from '@angular/core';
// language list
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import {environment, toggleBackend, toggleControl} from '../environments/environment';
import {TableService} from './_metronic/shared/crud-table';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  public env = environment;

  constructor(
    private splashScreenService: SplashScreenService,
    private router: Router,
    private tableService: TableServiceImplementation,
    private http: HttpClient,
    private titleService: Title,
    @Inject(Window) private window: Window,
  ) {
    const domain = location.hostname;
    if (domain !== 'localhost') {
      this.env.domain = domain;
    }
  }

  ngOnInit() {
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 500);

        this.tableService.reloadPaginator();

        this.env = environment;
      }
    });
    if (sessionStorage.getItem('domenConfig')) { environment.config = JSON.parse(sessionStorage.getItem('domenConfig')); }
    this.http.get<any>(`${environment.apiUrl}/api`).toPromise().then((res) => {
      if (res.data.data) {
        environment.config = res.data.data;
        document.getElementById('appFavicon').setAttribute('href', environment.config['favicon']);
        this.titleService.setTitle(environment.config['title']);
        sessionStorage.setItem('domenConfig', JSON.stringify(environment.config));
      } else {
        environment.config = {
          favicon: 'assets/media/logos/logo_transparent.png',
          logo: 'assets/media/logos/logo_transparent.png'
        };
        document.getElementById('appFavicon').setAttribute('href', environment.config['favicon']);
        sessionStorage.setItem('domenConfig', JSON.stringify(environment.config));
      }
      // @ts-ignore
      environment.config.client_id = res.data.client_id;
      sessionStorage.setItem('domenConfig', JSON.stringify(environment.config));
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  // todo убрать
  changeKeys() {
    if (this.env.domain === 'adposting.dev.zenzenzen.net') {
      toggleBackend();
      this.env = environment;
    } else {
      toggleControl();
      this.env = environment;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
class TableServiceImplementation extends TableService<any> {

    constructor(@Inject(HttpClient) http) {
      super(http);
    }

}
